<template>
  <ion-page>
    <ion-header class="flex justify-center border-b">
      <ion-toolbar class="flex items-center lg:max-w-screen-sm">
        <ion-buttons slot="start">
          <ion-back-button
            :default-href="isCreate ? '/earn/checklist' : '/earn'"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>{{ isCreate ? "Create" : "Edit" }} Profile</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex w-full justify-center">
        <ion-spinner v-if="isLoading"></ion-spinner>
      </div>
      <div
        class="flex justify-center mb-24"
        :class="{ 'opacity-25': isLoading }"
      >
        <div class="max-w-screen-sm w-full flex flex-col items-center gap-2">
          <section v-if="isCreate" class="flex flex-col gap-2 py-4 px-8">
            <div class="flex flex-col items-center text-center py-3 gap-2">
              <div class="text-6xl">🏷️</div>
              <div class="text-2xl font-bold">Create your profile</div>
              <div>
                Write your bio, set up your offers, and upload an intro video
              </div>
            </div>
          </section>

          <section>
            <div class="mt-4 flex flex-col items-center justify-center">
              <AvatarGroup
                v-if="currentUser?.id"
                :users="[currentUser]"
                :display-limit="2"
                size="large"
              />
              <ion-button fill="clear" size="small" @click="handleEditAvatar()">
                Change Avatar
              </ion-button>
              <ion-modal
                ref="changeAvatarRef"
                :is-open="isOpenChangeAvatar"
                @didDismiss="isOpenChangeAvatar = false"
              >
                <AvatarUpload
                  :image="selectedAvatarImage"
                  @close="isOpenChangeAvatar = false"
                ></AvatarUpload>
              </ion-modal>
            </div>
          </section>

          <!-- Menu -->
          <section class="flex flex-col gap-2 w-full">
            <div class="flex justify-center">
              <ion-button
                size="small"
                :href="`//${
                  isLocalhost ? 'localhost:5007' : 'me.zulu.video'
                }/u/${currentUser.username}/`"
                target="_blank"
              >
                Preview page
              </ion-button>
            </div>
            <!-- Main Content -->
            <div>
              <ion-list-header>
                <ion-label class="my-0">Main Content</ion-label>
              </ion-list-header>
              <ion-list inset>
                <ion-item
                  detail
                  class="w-full"
                  :router-link="{ name: 'earn-edit-page-bio' }"
                >
                  Bio
                </ion-item>
                <ion-item class="w-full">
                  <div class="flex flex-col w-full">
                    <div class="w-full flex justify-between items-center">
                      <div class="flex items-center">
                        Intro Video
                        <span class="text-xs opacity-50 ml-2">(optional)</span>
                      </div>
                      <ion-button
                        slot="end"
                        fill="clear"
                        class="font-semibold"
                        @click="handleIntroUpload()"
                      >
                        {{
                          currentUserMeProfile?.videoURL ? "Change" : "Upload"
                        }}
                      </ion-button>
                    </div>
                    <div
                      v-if="currentUserMeProfile?.videoURL"
                      class="w-full flex justify-center h-[350px] mb-4"
                    >
                      <VideoPlayer
                        :source="currentUserMeProfile.videoURL"
                        fill
                        class="rounded-lg overflow-hidden"
                      ></VideoPlayer>
                    </div>
                  </div>
                </ion-item>
              </ion-list>
            </div>

            <div>
              <ion-list-header>
                <ion-label class="my-0">Offers</ion-label>
                <ion-button
                  :router-link="{ name: 'earn-edit-page-create-offer' }"
                  class="text-sm"
                  >Create Offer</ion-button
                >
              </ion-list-header>
              <ion-list v-if="currentUserMeProfile?.packages?.length" inset>
                <ion-item
                  v-for="offer in currentUserMeProfile?.packages"
                  :key="offer.id"
                  detail
                  class="w-full"
                  :router-link="{
                    name: 'earn-edit-page-edit-offer',
                    params: { offerId: offer.id },
                  }"
                >
                  {{ offer.title }}
                  <div slot="end" class="italic text-sm text-gray-500">
                    ({{ offer.status }})
                  </div>
                </ion-item>
              </ion-list>
              <ion-list inset v-else class="">
                <ion-item>
                  <div class="flex flex-col text-center py-4 text-gray-500">
                    <p>
                      <i><strong>Offers</strong></i> are what people purchase
                      from you!
                    </p>
                    <p>
                      Create your first offer by pressing the
                      <strong>Create Offer</strong> button above
                    </p>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </section>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import AvatarGroup from "@/components/AvatarGroup";
import AvatarUpload from "../profile/AvatarUpload.vue";
import VideoPlayer from "@/components/VideoPlayer";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonButton,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonSpinner,
  IonModal,
  onIonViewWillEnter,
  toastController,
} from "@ionic/vue";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { db, storage } from "@/plugins/firebase";
import { promptForFileSelection, getFileBlobURLs } from "@/utilities/file";

const isLoading = ref(false);
const isLocalhost = location.hostname === "localhost";

const route = useRoute();
const isCreate = computed(() => {
  return route.name === "earn-create-page";
});

const store = useStore();
const currentUser = computed(() => store.state.firebaseData.currentUser);
const currentUserMeProfile = computed(
  () => store.state.firebaseData.currentUserMeProfile
);

const isOpenChangeAvatar = ref(false);
const selectedAvatarImage = ref();
const handleEditAvatar = async () => {
  const files = await promptForFileSelection({ accept: "image/*" });
  const fileURLs = getFileBlobURLs(files);
  if (fileURLs.length) {
    selectedAvatarImage.value = fileURLs[0];
    isOpenChangeAvatar.value = true;
  }
};

// Intro Video
const handleIntroUpload = async () => {
  const files = await promptForFileSelection({ accept: "video/*" });

  if (!files.length) {
    return;
  }

  const file = files[0];

  // Upload video
  isLoading.value = true;

  try {
    // Upload avatar
    const uploadDestination = `profiles/${currentUser.value.id}/intro`;
    const videoRef = storage.child(uploadDestination);
    await videoRef.put(file);

    // Update profile
    await db
      .collection("users")
      .doc(currentUser.value.id)
      .collection("pages")
      .doc("profile")
      .update({ videoURL: await videoRef.getDownloadURL() });
  } catch {
    const toast = await toastController.create({
      header: "Failed to upload intro video",
      message: "Please try reselecting and reuploading your video",
      color: "danger",
      duration: 4000,
      position: "top",
    });
    await toast.present();
  }
  isLoading.value = false;
};

onIonViewWillEnter(async () => {
  // If not exist, create
  if (!currentUserMeProfile.value?.id) {
    isLoading.value = true;
    try {
      await db
        .collection("users")
        .doc(currentUser.value.id)
        .collection("pages")
        .doc("profile")
        .set({
          description: "",
          packages: [],
        });
    } catch (e) {
      console.error(e);
    }
    isLoading.value = false;
  }
});
</script>

<style scoped>
ion-header {
  --background: red;
}
</style>
