import { createRouter, createWebHistory } from "@ionic/vue-router";
import { auth } from "@/plugins/firebase";
import store from "@/store/store";
import { ref } from "vue";

// Components
import TabsPage from "../views/TabsPage.vue";
import GroupsSplitView from "@/views/groups/GroupsSplitView.vue";
import GroupsTab from "@/views/groups/GroupsTab.vue";
import GroupsPage from "@/views/groups/GroupsPage.vue";
import GroupPage from "@/views/groups/group/GroupPage.vue";
import NotificationsPage from "@/views/groups/NotificationsPage.vue";
import EarnTab from "@/views/earn/EarnTab.vue";
import EarnPage from "@/views/earn/EarnPage.vue";
import ProfileTab from "@/views/profile/ProfileTab.vue";
import ProfilePage from "@/views/profile/ProfilePage.vue";
import SetupChecklist from "@/views/earn/SetupChecklist.vue";
import ConfigurationPage from "@/views/earn/ConfigurationPage.vue";
import SetupStripe from "@/views/earn/SetupStripe.vue";
import ManagePlan from "@/views/earn/ManagePlan.vue";
import EditPage from "@/views/earn/EditPage.vue";
import EditBio from "@/views/earn/EditBio.vue";
import EditOffer from "@/views/earn/EditOffer.vue";
import CreateOffer from "@/views/earn/CreateOffer.vue";
import SelectUsername from "@/views/earn/SelectUsername.vue";
import StaticTopBar from "@/layouts/StaticTopBar.vue";
import UserProfile from "@/views/user-profile/UserProfile.vue";
import CheckoutTopBarLayout from "@/layouts/CheckoutTopBarLayout.vue";
import CheckoutWrapper from "@/views/checkout/CheckoutWrapper.vue";
import CheckoutRegistration from "@/views/checkout/CheckoutRegistration.vue";
import CheckoutPayment from "@/views/checkout/CheckoutPayment.vue";
import CheckoutConfirmation from "@/views/checkout/CheckoutConfirmation.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import RegisterUser from "@/views/auth/RegisterUser.vue";
import LoginUser from "@/views/auth/LoginUser.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";

const popStateDetected = ref(false);
window.addEventListener("popstate", () => {
  popStateDetected.value = true;
});

const routes = [
  {
    path: "/",
    component: TabsPage,
    redirect: "/groups",
    children: [
      {
        path: "/groups",
        component: GroupsTab,
        children: [
          {
            path: "",
            name: "groups",
            meta: {
              authRequired: true,
            },
            component: GroupsSplitView,
            children: [
              {
                name: "groups-list",
                path: "",
                meta: {
                  authRequired: true,
                },
                component: GroupsPage,
              },
              {
                name: "group",
                path: "/groups/:groupId/",
                meta: {
                  authRequired: true,
                  hideTabBar: true,
                },
                component: GroupPage,
              },
            ],
          },
          {
            path: "notifications",
            name: "notifications",
            meta: {
              authRequired: true,
            },
            component: NotificationsPage,
          },
        ],
      },

      {
        path: "/earn",
        meta: {
          authRequired: true,
        },
        component: EarnTab,
        children: [
          {
            path: "",
            name: "earn",
            component: EarnPage,
            meta: {
              authRequired: true,
            },
          },
          {
            path: "/earn/setup/checklist",
            name: "earn-setup-checklist",
            meta: {
              authRequired: true,
            },
            component: SetupChecklist,
          },
          {
            path: "/earn/plan/manage",
            name: "earn-manage-plan",
            meta: {
              authRequired: true,
              // hideTabBar: true,
            },
            component: ManagePlan,
          },

          {
            path: "/earn/configure",
            name: "earn-configure",
            meta: {
              authRequired: true,
              // hideTabBar: true,
            },
            component: ConfigurationPage,
          },

          {
            path: "/earn/username",
            name: "select-username",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: SelectUsername,
          },
          {
            path: "/earn/page/create",
            name: "earn-create-page",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: EditPage,
          },

          {
            path: "/earn/page/edit",
            name: "earn-edit-page",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: EditPage,
          },

          {
            path: "/earn/page/edit/bio",
            name: "earn-edit-page-bio",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: EditBio,
          },

          {
            path: "/earn/page/edit/offer/create",
            name: "earn-edit-page-create-offer",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: CreateOffer,
          },

          {
            path: "/earn/page/edit/offer/:offerId",
            name: "earn-edit-page-edit-offer",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: EditOffer,
          },

          {
            path: "/earn/stripe/setup",
            name: "earn-setup-stripe",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: SetupStripe,
          },

          {
            path: "/earn/plan/manage",
            name: "manage-plan",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: ManagePlan,
          },

          {
            path: "/earn/billing/setup",
            name: "setup-billing",
            meta: {
              authRequired: true,
              hideTabBar: true,
            },
            component: ManagePlan,
          },
        ],
      },

      {
        path: "/profile",
        meta: {
          authRequired: true,
        },
        component: ProfileTab,
        children: [
          {
            path: "",
            name: "profile",

            meta: {
              authRequired: true,
            },
            component: ProfilePage,
          },
        ],
      },
    ],
  },
  {
    path: "/u",
    component: StaticTopBar,
    children: [
      {
        path: "/u/:username",
        name: "user-profile",
        component: UserProfile,
      },
    ],
  },
  {
    path: "/checkout",
    component: CheckoutTopBarLayout,
    children: [
      {
        path: "/checkout",
        component: CheckoutWrapper,
        children: [
          {
            path: "/checkout/register",
            name: "checkout-register",
            component: CheckoutRegistration,
          },
          {
            path: "/checkout/payment",
            name: "checkout-payment",
            component: CheckoutPayment,
          },
          {
            path: "/checkout/confirmation",
            name: "checkout-confirmation",
            component: CheckoutConfirmation,
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        path: "/auth/register",
        name: "register",
        component: RegisterUser,
      },
      {
        path: "/auth/login",
        name: "login",
        component: LoginUser,
      },
      {
        path: "/auth/reset-password",
        name: "reset-password",
        component: ResetPassword,
      },
    ],
  },
  {
    path: "/api",
  },
  { path: "/ffmpeg/ffmpeg-worker-webm.js" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Auth
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (auth.currentUser) {
      next();
    } else {
      next({
        path: "/auth/login",
        query: {
          redirect: to.path,
        },
      });
    }
  } else {
    next();
  }

  // Route in Vuex store
  store.commit("SET_ROUTE", to);
});

router.afterEach(() => {
  popStateDetected.value = false;
});

export default router;
export const isManualBackNavigation = popStateDetected;
