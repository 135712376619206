<template>
  <div id="audioControls" class="w-full h-full relative">
    <div class="bg-secondary h-full w-full">
      <!-- Video Player -->

      <video
        class="hidden w-full h-full"
        ref="videoElement"
        :src="props?.source"
        :autoplay="props.autoplay"
        playsinline
        :loop="props.loop"
        @error="handleError"
      ></video>

      <!-- Controls -->
      <div
        class="absolute w-full h-full flex flex-col justify-between pointer-events-none text-white"
      >
        <!-- Loading Spinner -->
        <div
          v-if="isLoading"
          class="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center bg-black/50 animate-pulse"
        >
          <ion-spinner
            name="crescent"
            color="light"
            class="absolute h-10 w-10"
          ></ion-spinner>
        </div>

        <!-- Middle -->
        <div
          v-if="!props.compact"
          class="flex w-full h-full items-center justify-center grow pointer-events-auto"
          @click="togglePlayback()"
        >
          <ion-icon :icon="micOutline" class="text-8xl text-white"></ion-icon>
        </div>

        <!-- Footer -->
        <div class="flex flex-col pointer-events-auto">
          <div
            class="flex w-full px-3 py-2 items-center"
            :class="{ 'backdrop-blur-sm bg-black/30': !props.compact }"
          >
            <div class="flex">
              <button
                aria-label="play/pause"
                class="cursor-pointer rounded-full flex justify-center items-center h-8 w-8 bg-white"
                @click="togglePlayback()"
              >
                <ion-icon
                  class="text-secondary"
                  :icon="isPlaying ? pause : play"
                ></ion-icon>
              </button>
            </div>
            <div class="grow w-full flex items-center text-sm font-medium px-3">
              {{ timeElapsedFormatted }}
              <template v-if="durationFormatted">
                <ion-range
                  color="light"
                  :value="timeElapsedProgress"
                  aria-label="time slider"
                  class="p-0 mx-6"
                  @ion-knob-move-start="sliderStarted"
                  @ion-knob-move-end="sliderEnded"
                ></ion-range>
                <div class="text-sm font-medium">{{ durationFormatted }}</div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, defineProps, defineExpose, onMounted } from "vue";
import { IonIcon, IonRange, IonSpinner } from "@ionic/vue";

import { play, pause, micOutline } from "ionicons/icons";
import moment from "moment";
import { getTimeString } from "@/utilities/time";

const props = defineProps({
  source: [Object, String],
  fill: Boolean,
  loop: Boolean,
  autoplay: Boolean,
  compact: Boolean,
});

// Video Player
const videoElement = ref(null);

// const selectedMessage = computed(() => {
//   return store.state.firebaseData.selectedMessage;
// });

const isLoading = ref(false);
const isPlaying = ref(false);
const duration = ref(null);
const currentTime = ref(videoElement.value?.currentTime);

const durationFormatted = computed(() => {
  if (duration.value === Infinity) return "∞";
  const durationTime = moment(0);
  durationTime.set("second", duration.value);
  return getTimeString(durationTime);
});

const timeElapsedFormatted = computed(() => {
  const timeElapsed = moment(0);
  timeElapsed.set("second", currentTime.value);
  return getTimeString(timeElapsed);
});

const timeElapsedProgress = computed(() => {
  if (!duration.value) return 0;
  return (currentTime.value / duration.value) * 100;
});

const wasPlaying = ref(false);
const isSliderScrubbing = ref(false);
const sliderStarted = () => {
  wasPlaying.value = false;
  wasPlaying.value = isPlaying.value;
  togglePlayback();
  isSliderScrubbing.value = true;
};
const sliderEnded = (event) => {
  isSliderScrubbing.value = false;
  const percentage = event.detail?.value / 100;
  const seconds = duration.value * percentage;
  // eslint-disable-next-line vue/no-mutating-props
  videoElement.value.currentTime = seconds;
  if (wasPlaying.value) {
    togglePlayback();
  }
};

// Mounted
onMounted(() => {
  // VIDEO EVENT LISTENERS

  // Loaded Metadata
  videoElement.value.addEventListener("loadedmetadata", () => {
    isLoading.value = false;
  });
  // Duration Change
  videoElement.value.addEventListener("durationchange", () => {
    duration.value = videoElement.value.duration;
  });
  // Time Update
  videoElement.value.addEventListener("timeupdate", () => {
    if (!isSliderScrubbing.value) {
      currentTime.value = videoElement.value?.currentTime;
    }
  });
  // Can Play
  videoElement.value.addEventListener("canplay", () => {
    isLoading.value = false;
  });
  // Waiting
  videoElement.value.addEventListener("waiting", () => {
    isLoading.value = true;
  });
  // Load Start
  videoElement.value.addEventListener("loadstart", () => {
    isLoading.value = true;
  });
  // Play
  videoElement.value.addEventListener("play", () => {
    console.log("play");
    isPlaying.value = true;
  });
  // Pause
  videoElement.value.addEventListener("pause", () => {
    console.log("pause");
    isPlaying.value = false;
  });
  // Ended
  videoElement.value.addEventListener("ended", () => {
    currentTime.value = 0;
  });
  // Volume Change
  videoElement.value.addEventListener("volumechange", () => {});
  // Fullscreen Change
  videoElement.value.addEventListener("webkitfullscreenchange", () => {});
});

const togglePlayback = () => {
  if (isPlaying.value) {
    videoElement.value.pause();
  } else {
    // eslint-disable-next-line vue/no-mutating-props
    videoElement.value.setAttribute("playsinline", true);
    // eslint-disable-next-line vue/no-mutating-props
    videoElement.value.removeAttribute("controls");

    videoElement.value.play();
  }
};

const handleError = (event) => {
  console.error(event);
};

defineExpose({
  isPlaying,
  togglePlayback,
});
</script>
