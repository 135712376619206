// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2pMH8Lucx6tZ-N3Zi0jvxMa_ejr6OWQY",
  authDomain: "zulu-1c81b.firebaseapp.com",
  projectId: "zulu-1c81b",
  storageBucket: "zulu-1c81b.appspot.com",
  messagingSenderId: "645275133505",
  appId: "1:645275133505:web:fd39efe3f791fa6c9ec8c0",
  measurementId: "G-7Z41L36H26",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// Get a Firestore instance
const db = app.firestore();

const storageInstance = app.storage();
const storage = storageInstance.ref();

const provider = new firebase.auth.GoogleAuthProvider();
const auth = new firebase.auth();

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

if (location.hostname === "localhost") {
  auth.useEmulator(`http://${location.hostname}:9099`);
  db.useEmulator(location.hostname, 8080);
  storageInstance.useEmulator(location.hostname, 9199);
}

export { db, auth, provider, storage };
