<template>
  <div class="w-full flex justify-center">
    <div class="max-w-screen-lg flex flex-col items-center">
      <div
        v-if="isPackageOwner"
        class="flex flex-col gap-2 items-center bg-red-100 border border-red-300 sm:rounded-lg text-red-700 max-w-screen-sm p-2"
      >
        <p class="text-center">
          <span class="font-bold">PREVIEW MODE</span>
          <br />
          Since you are the owner of this offer, this is a
          <strong><i>simulated</i></strong> checkout process so that you can
          understand the process your clients will go through.
        </p>
        <p class="text-center">
          The <strong>Sign Up/Sign In</strong> form will
          <strong><i>NOT</i></strong> work in preview mode.
          <br />
          Use the <strong>Next Step</strong> button to proceed
        </p>
        <div class="flex items-center">
          <button
            class="bg-red-500 rounded-md px-4 py-3 text-white font-bold"
            @click="
              router.push({ name: 'checkout-payment', query: route.query })
            "
          >
            Next Step →
          </button>
        </div>
      </div>

      <div
        class="flex flex-col lg:flex-row h-full w-full items-center lg:items-start lg:pt-12 gap-8 lg:gap-24 lg:justify-center"
      >
        <div
          class="flex flex-col gap-6 p-6 text-center lg:text-left text-black"
        >
          <div class="text-4xl font-extrabold mt-8">Let's get you chatting</div>
          <div class="text-xl">
            <p>
              In order to purchase a package from a seller, you need to
              <span class="font-extrabold italic">create a Zulu</span>
              account.
              <br />
              This allows us to make a private conversation between you and the
              seller
            </p>
          </div>
        </div>
        <div
          class="flex flex-col w-full sm:max-w-[400px] items-center sm:rounded-2xl p-6 gap-6 bg-gradient-to-b from-primary to-secondary"
        >
          <div class="lg:w-[350px] w-full text-black" id="formSelector">
            <ion-segment v-model="currentSelection" class="bg-black/20">
              <ion-segment-button value="new">
                <ion-label class="text-base p-1">New User</ion-label>
              </ion-segment-button>
              <ion-segment-button value="existing">
                <ion-label class="text-base p-1">Existing</ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>
          <div
            class="flex justify-center w-full text-white"
            :class="{ 'pointer-events-none': isPackageOwner }"
          >
            <register-user-form
              v-if="currentSelection === 'new'"
              :metadata="{ origin: 'checkout', userType: 'customer' }"
              class="w-full m-0"
            />
            <login-user-form
              v-else
              class="w-full m-0"
              @success="
                router.push({ name: 'checkout-payment', query: route.query })
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import RegisterUserForm from "@/views/auth/RegisterUserForm.vue";
import LoginUserForm from "@/views/auth/LoginUserForm.vue";
import { auth } from "@/plugins/firebase";
import { isPackageOwner, getOffer } from "./checkout";
import { ref, onMounted } from "vue";
import {
  useIonRouter,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/vue";
import { useRoute } from "vue-router";

const route = useRoute();
const router = useIonRouter();

const currentSelection = ref("new");

onMounted(async () => {
  // Route to payment page automatically if logged in and not package owner (for simulation walkthrough)
  if (auth.currentUser) {
    await getOffer(route.query.packageId);

    if (!isPackageOwner.value) {
      router.replace({ name: "checkout-payment", query: route.query });
    }
  }
});
</script>

<style>
#formSelector ion-segment-button::part(indicator-background) {
  background: var(--ion-color-primary-tint);
}

#formSelector ion-segment-button::part(native) {
  color: #fff;
}

#formSelector .segment-button-checked::part(native) {
  color: var(--ion-color-primary);
}

#formSelector ion-segment-button::part(indicator-background) {
  background: #fff;
}
</style>
